<template>
  <v-dialog max-width="800" v-model="value" @click:outside="close">
    <v-card class="pa-5">
      <v-card-title class="justify-center">
        <h2>
          <span class="orange--text">{{
            !!userId ? "Редактирование пользователя" : "Создание пользователя"
          }}</span>
        </h2>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field v-model="command.name" label="Полное имя" required>
          </v-text-field>
          <v-text-field v-model="command.email" label="Емаил" required>
          </v-text-field>
          <v-text-field
            :append-icon="configs.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="configs.showPassword ? 'text' : 'password'"
            @click:append="configs.showPassword = !configs.showPassword"
            v-model="command.password"
            label="Пароль"
          >
          </v-text-field>

          <v-select
            v-model="command.role"
            :items="roles"
            label="Роль"
            item-text="name"
            item-value="value"
            persistent-hint
            single-line
          ></v-select>
          <v-row align="center">
            <v-col cols="4">
              <v-checkbox
                label="Генерировать QR"
                v-model="command.accountSetting.canGenerateQR"
                color="orange"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                label="Получать отчёты"
                v-model="command.accountSetting.canGetReport"
                color="orange"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                label="Отправлять SMS-сообщения"
                color="orange"
                v-model="command.accountSetting.canSendSMS"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row justify="space-between" align="center">
            <v-col cols="4" class="my-3 pt-4">
              <v-checkbox
                v-model="configs.company"
                label="Привязать к компании"
                color="orange"
                class="mt-0"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="8">
              <v-select
                v-if="configs.company"
                v-model="command.accountSetting.bpoCompanyId"
                :items="companies"
                item-text="name"
                item-value="id"
                label="Выберите компанию"
              />
            </v-col>
          </v-row>
          <v-row justify="space-between" align="center">
            <v-col cols="4" class="my-3 pt-4">
              <v-checkbox
                v-model="configs.airport"
                label="Привязать к порту"
                color="orange"
                class="mt-0"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="8">
              <v-select
                v-if="configs.airport"
                v-model="command.accountSetting.airportId"
                :items="airports"
                item-text="name"
                item-value="id"
                label="Выберите аэропорт"
              />
            </v-col>
          </v-row>
          <v-row justify="space-between" align="center">
            <v-col cols="4" class="my-3 pt-4">
              <v-checkbox
                v-model="configs.kkt"
                label="Привязать к Ккт"
                color="orange"
                class="mt-0"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-if="configs.kkt"
                v-model="command.accountSetting.kktRegId"
                label="Reg Id (16 цифр: пример - 0006470395055076)"
                required
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="close"> Отмена </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save"> Сохранить </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditEmployee",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      default: null,
    },
  },
  data: function () {
    return {
      loading: false,
      command: {
        id: null,
        email: "",
        role: null,
        name: "",
        password: "",
        accountSetting: {
          id: null,
          bpoCompanyId: null,
          airportId: null,
          kktRegId: "",
          canGenerateQr: false,
          canSendSMS: false,
          canGetReport: false,
        },
      },
      userData: null,
      configs: {
        airport: false,
        company: false,
        kkt: false,
        showPassword: false,
      },
      roles: [
        { name: "Администратор", value: 0 },
        { name: "Кассир", value: 1 },
        { name: "Технолог", value: 5 },
        { name: "СубАгент", value: 3 },
        { name: "Только чтение", value: 4 },
      ],
      airports: [
        { name: "Шереметьево", id: 2009 },
        { name: "Домодедово", id: 2010 },
        { name: "Пулково", id: 1707 },
      ],
      companies: [],
    };
  },
  async mounted() {
    this.companies = await this.getCompanies();
  },
  watch: {
    async userId(val) {
      if (!val) {
        this.userData = null;
        this.configs = {
          airport: false,
          company: false,
          kkt: false,
          showPassword: false,
        };
        return;
      }
      this.loading = true;
      let resp = await this.$axios.get(
        this.$enums.Endpoints.Account.Get + `?id=${this.userId}`
      );
      this.userData = resp.data;
      this.configs = {
        airport: !!this.userData.accountSetting.airport,
        company: !!this.userData.accountSetting.bpoCompany,
        kkt: !!this.userData.accountSetting.kkt,
        showPassword: false,
      };
      this.loading = false;
      console.warn(this.userData);
    },
    userData(val) {
      if (!val)
        this.command = {
          id: null,
          email: "",
          role: null,
          name: "",
          password: "",
          accountSetting: {
            id: null,
            bpoCompanyId: null,
            airportId: null,
            kktRegId: "",
            canGenerateQr: false,
            canSendSMS: false,
            canGetReport: false,
          },
        };
      else
        this.command = {
          id: val.id,
          email: val.email,
          name: val.name,
          password: val.password,
          role: val.role,
          accountSetting: {
            id: val.accountSetting.id,
            airportId: val.accountSetting.airport?.id,
            bpoCompanyId: val.accountSetting.bpoCompany?.id,
            kktRegId: val.accountSetting.kkt?.regId,
            canGenerateQR: val.accountSetting.canGenerateQR,
            canGetReport: val.accountSetting.canGetReport,
            canSendSMS: val.accountSetting.canSendSMS,
          },
        };
    },
    configs: {
      handler(val) {
        if (!val.airport) this.command.accountSetting.airportId = null;
        if (!val.company) this.command.accountSetting.bpoCompanyId = null;
        if (!val.kkt) this.command.accountSetting.kktRegId = "";
      },
      deep: true,
    },
  },
  methods: {
    save() {
      this.$store.dispatch({
        type: "setActions",
        accept: this.saveInternal,
      });
      this.$store.commit("setMessage", "что хотите сохранить");
      this.$store.commit("openDialog", true);
    },
    async saveInternal() {
      try {
        const url = !!this.userId
          ? this.$enums.Endpoints.Account.Edit
          : this.$enums.Endpoints.Account.Create;
        let resp = await this.$axios.post(url, this.command);
        if (resp.data.succeeded) {
          this.$emit("save", resp.data.resultData);
          // закрываем модалку
          this.close();
        } else
          this.$store.state.error = {
            show: true,
            value: {
              Code: 400,
              Message: resp.data.errors[0],
            },
          };
      } catch (e) {
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response ? e.response.status : "Unknown",
            Message: e.response ? e.response.data : "No response data",
          },
        };
      }
    },
    updateData(user) {
      let airport = this.findObjectById(
        this.airports,
        user.accountSetting.airportId
      );
      let company = this.findObjectById(
        this.companies,
        user.accountSetting.bpoCompanyId
      );

      user.accountSetting.airport = !!airport
        ? { nameRu: airport.name, id: airport.value }
        : null;
      user.accountSetting.bpoCompany = !!company
        ? { name: company.name, id: company.id }
        : null;
      user.accountSetting.kkt = !!kkt
        ? {
            regId: kkt.regId,
            id: kkt.id,
            storageId: kkt.storageId,
            status: kkt.status,
          }
        : null;
    },

    close() {
      this.$emit("close");
    },

    async getCompanies() {
      let resp = await this.$axios.get(this.$enums.Endpoints.Data.Companies);
      return resp.data;
    },
  },
};
</script>

<template>
  <v-container fluid>
    <edit-employee
      v-model="modalpopup"
      :user-id="selectedUser"
      @save="refresh"
      @close="closeModal"
    ></edit-employee>
    <v-container class="filters">
      <v-row justify="start" class="mb-1">
        <v-col cols="12" md="3">
          <v-text-field
            label="Фамилия или емаил"
            v-model="fieldForFind"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-select label="Роль" :items="roles" v-model="selectedRole">
          </v-select>
        </v-col>
        <v-col outlined tile>
          <v-btn block dark color="orange" elevation="0" v-on:click="refresh">
            Поиск
          </v-btn>
        </v-col>
        <v-col
          outlined
          tile
          v-if="this.$store.getters.user.role === this.$enums.Roles.Admin"
        >
          <v-btn
            block
            dark
            color="green"
            evelation="0"
            @click="selectUser(null)"
          >
            Создать
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-row v-if="loading" no-gutters>
      <v-col>
        <overlay-loader :loading="loading"></overlay-loader>
      </v-col>
    </v-row>

    <div v-else-if="Employees.length" class="mt-sm-3">
      <v-row v-for="(emp, i) in sortedEmployees" :key="emp.id">
        <employee-item
          :user="emp"
          :number="i"
          @block="refresh"
          @select="selectUser"
        />
      </v-row>
    </div>
  </v-container>
</template>

<script>
import EmployeeItem from "@/components/EmployeePage/EmployeeItem.vue";
import OverlayLoader from "@/components/UI/OverlayLoader.vue";
import EditEmployee from "@/components/EmployeePage/EditEmployee.vue";
import ApiCalls from "@/mixins/booking/ApiCalls";
import DateFormat from "@/mixins/DateFormat";

export default {
  name: "EmployeesList",
  components: { OverlayLoader, EmployeeItem, EditEmployee },
  mixins: [ApiCalls, DateFormat],
  data() {
    return {
      loading: true,
      Employees: [],
      fieldForFind: "",
      modalpopup: false,
      selectedRole: 1,
      selectedUser: null,
      roles: [
        {
          text: "Кассир",
          value: 1,
        },
        {
          text: "Администратор",
          value: 0,
        },
        {
          text: "СубАгент",
          value: 3,
        },
        {
          text: "Только чтение",
          value: 4,
        },
        {
          text: "Технолог",
          value: 5,
        },
      ],
    };
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      this.Employees = await this.getEmployees(
        this.fieldForFind,
        this.selectedRole
      );
      console.warn(this.Employees);
      this.loading = false;
    },
    closeModal() {
      this.modalpopup = false;
    },
    async getEmployees(fieldForFind = "", role = 1) {
      try {
        let resp = await this.$axios.get(
          this.$enums.Endpoints.Account.GetList +
            `?text=${fieldForFind}&role=${role}`
        );
        return resp.data.results;
      } catch (e) {
        console.log("getEmployees - Error");
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        };
        return [];
      }
    },
    selectUser(id) {
      this.selectedUser = id;
      this.modalpopup = true;
    },
  },
  computed: {
    sortedEmployees: function () {
      return this.Employees.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    },
  },
};
</script>

<template>
  <v-card flat class="px-5 pb-5">
    <v-card-title class="pb-8">Получение бланка WWSS</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3" class="text-color">
          <date-only-picker
            outlined
            v-model="value.date"
            text="Дата составления документа"
            format="dd.MM.yyyy"
          />
        </v-col>
        <v-col cols="12" md="4" class="text-color">
          <v-text-field
            outlined
            label="Фамилия пассажира"
            v-model="value.customer"
          />
        </v-col>
        <v-col cols="12" md="4" class="text-color">
          <v-select
            outlined
            label="Форма оплаты"
            :items="paymentForms"
            v-model="value.paymentForm"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col col="12" v-for="(service, index) in value.services" :key="index">
          <accomodation :accomodation="service" :variations="namesArray" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row class="px-4">
        <v-col cols="6" md="3">
          <v-btn
            block
            dark
            color="orange"
            :disabled="isDownload"
            style="width: 100%; height: 3.3rem"
            @click="clear"
            >Очистить форму
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="6" md="3">
          <v-btn
            block
            dark
            :loading="isDownload"
            color="green"
            :disabled="isDisabled"
            style="width: 100%; height: 3.3rem"
            @click="downloadBlank"
            >Получить бланк
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import DateOnlyPicker from "@/components/UI/DateOnlyPicker.vue";
import Accomodation from "./Accomodation.vue";
import { downloadFile } from "@/mixins/utils.js";

export default {
  components: { DateOnlyPicker, Accomodation },
  name: "Wwss",
  data() {
    return {
      value: {
        date: new Date().toLocaleDateString(),
        paymentForm: 0,
        customer: null,
        services: [
          {
            name: null,
            documentNumber: null,
            priceWithoutNDS: null,
            NDS: null,
          },
        ],
      },
      namesArray: [
        "Утерянная вещь",
        "Невостребованный багаж",
        "Ручная кладь",
        "Служба досмотра",
      ],
      paymentForms: [
        { text: "Наличные", value: 0 },
        { text: "Безнал", value: 1 },
      ],
      isDownload: false,
    };
  },
  methods: {
    async downloadBlank() {
      this.isDownload = true;
      try {
        let resp = await this.$axios.post(
          this.$enums.Endpoints.Other.CreateBlank,
          this.value
        );
        if (!resp.data.succeeded) return;
        let uint8Array = new Uint8Array(
          atob(resp.data.resultData)
            .split("")
            .map(function (c) {
              return c.charCodeAt(0);
            })
        );
        downloadFile(uint8Array, { name: `${this.value.customer}_wwss.pdf` });
      } catch (e) {
        console.error(e);
      } finally {
        this.isDownload = false;
      }
    },
    clear() {
      this.value = {
        date: new Date().toLocaleDateString(),
        paymentForm: 0,
        customer: null,
        services: [
          {
            name: null,
            documentNumber: null,
            priceWithoutNDS: null,
            NDS: null,
          },
        ],
      };
    },
  },
  computed: {
    isDisabled() {
      return (
        !this.value ||
        this.value.paymentForm == undefined ||
        !this.value.customer ||
        !this.value.services ||
        !!this.value.services.length == 0 ||
        this.value.services.some(
          (e) =>
            !e.name ||
            !e.priceWithoutNDS ||
            isNaN(Number(this.value.services[0].priceWithoutNDS))
        )
      );
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-snackbar
      :value="value"
      @input="update"
      :color="color"
      :timeout="timeout"
      :content-class="contentClass"
      text
      centered
      multi-line

  >
    <h2>{{ message }}</h2>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  data() {
    return {
      show: false
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    contentClass: {
      type: String,
      default: "text-center",
    },
    timeout: {
      type: Number,
      default: 1000,
    }
  },
  model: {
    prop: 'value',
    event: 'changeState'
  },
  methods: {
    update(event) {
      this.$emit('changeState', event);
    }
  }
}
</script>

<template>
  <v-container fluid>
    <v-container class="filters">
      <v-row justify="start" class="mb-1">
        <v-col cols="12" md="3">
          <v-text-field
            label="Рег. Номер или Номер ФН"
            v-model="searchModel.text"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-select
            label="Статус"
            :items="combinedStatuses"
            v-model="searchModel.status"
          ></v-select>
        </v-col>

        <v-col outlined tile>
          <v-btn block dark color="orange" elevation="0" v-on:click="loadKKT">
            Поиск
          </v-btn>
        </v-col>

        <v-col
          outlined
          tile
          v-if="this.$store.getters.user.role === this.$enums.Roles.Admin"
        >
          <v-btn @click="openDialog" block dark color="green" elevation="0">
            Создать
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <edit-dialog
      :value="dialog"
      dialog-title="Создать ККТ"
      :kktData="newKkt"
      is-new
      @input="dialog = $event"
      @save="createKkt"
    />
    <kkt-table
      :loading="loading"
      :kkt-data="KKT"
      :total-rows="totalRows"
      @update:options="handleOptionsUpdate"
      @kkt-deleted="deregisterKkt"
      @edit-item="updateKkt"
    />
  </v-container>
</template>

<script>
import KktTable from "@/components/KKTPage/KktTable.vue";
import OverlayLoader from "@/components/UI/OverlayLoader.vue";
import axios from "axios";
import EditDialog from "@/components/KKTPage/EditDialog.vue";
import { kktStatuses } from "@/services/constants";

export default {
  name: "KKTCrud",
  components: { KktTable, OverlayLoader, EditDialog },
  data() {
    return {
      loading: true,
      searchModel: {
        status: null,
        text: null,
        pageIndex: 1,
        pageSize: 15,
        orderBy: "id",
        filter: null,
      },
      totalRows: 0,
      dialog: false,
      newKkt: {
        regId: "",
        storageId: "",
        sbisCompanyId: "",
      },

      KKT: [],
    };
  },
  computed: {
    combinedStatuses() {
      return [{ text: "Все", value: null }, ...kktStatuses];
    },
  },
  async mounted() {
    await this.loadKKT();
  },
  watch: {
    "searchModel.status": function (newVal, oldVal) {
      console.info(newVal, oldVal);
      if (newVal != oldVal) this.loadKKT();
    },
    "searchModel.pageIndex": function () {
      this.loadKKT();
    },
    "searchModel.pageSize": function () {
      this.loadKKT();
    },
    "searchModel.status": function () {
      this.loadKKT();
    },
  },
  methods: {
    async loadKKT() {
      this.loading = true;
      try {
        const response = await axios.get(this.$enums.Endpoints.Admin.Kkt.Get, {
          params: this.searchModel,
        });
        this.KKT = response.data.results;
        this.totalRows = response.data.rowCount;
      } catch (error) {
        console.error("Ошибка при запросе на сервер:", error);
      } finally {
        this.loading = false;
      }
    },

    handleOptionsUpdate(newOptions) {
      this.searchModel = {
        ...this.searchModel,
        pageSize: newOptions.itemsPerPage,
        pageIndex: newOptions.page,
      };
    },

    async deregisterKkt(id) {
      try {
        const deleteUrl = `${this.$enums.Endpoints.Admin.Kkt.Delete}/${id}`;
        await axios.put(deleteUrl);
        await this.loadKKT();
      } catch (error) {
        console.error("Ошибка при удалении ККТ:", error);
      }
    },

    async updateKkt(updatedKkt) {
      try {
        await axios.post(
          `${this.$enums.Endpoints.Admin.Kkt.Update}`,
          updatedKkt
        );
        await this.loadKKT();
      } catch (error) {
        console.error("Ошибка при обновлении ККТ:", error.message);
      }
    },
    openDialog() {
      this.dialog = true;
    },
    async createKkt(data) {
      try {
        await axios.post(this.$enums.Endpoints.Admin.Kkt.Create, data);
        this.dialog = false;
        await this.loadKKT();
      } catch (error) {
        console.error("Ошибка при создании ККТ:", error);
      }
    },
  },
};
</script>

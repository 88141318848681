<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="kktData"
      :loading="loading"
      :items-per-page="options.itemsPerPage"
      :server-items-length="totalRows"
      :options.sync="options"
      @update:options="updateOptions"
    >
      <template v-slot:body="{ items }">
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.regId }}</td>
          <td>{{ item.storageId }}</td>
          <td>{{ item.company.login }}</td>
          <td>{{ item.status.name }}</td>
          <td>
            <v-menu v-model="item.menu" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="openEditDialog(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Редактировать</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>

    <delete-dialog
      :dialog.sync="dialog"
      :item-to-delete="kktToDelete"
      @delete-confirmed="deleteKkt"
    />
    <edit-dialog
      v-model="modalpopup"
      :dialog-title="'Редактировать ККТ'"
      :kkt-data="editKktData"
      @save="saveKkt"
    />
  </v-container>
</template>

<script>
import DeleteDialog from "@/components/KKTPage/DeleteDialog.vue";
import EditDialog from "@/components/KKTPage/EditDialog.vue";

export default {
  name: "KktTable",
  components: { EditDialog, DeleteDialog },
  props: {
    kktData: {
      type: Array,
      required: true,
      default: () => [],
    },
    totalRows: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { text: "Регистрационный номер", value: "regId" },
        { text: "Номер ФН", value: "storageId" },
        { text: "Sbis Логин", value: "sbisLogin" },
        { text: "Статус", value: "status" },
        { text: "Редактирование", value: "actions", sortable: false },
      ],
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [],
      },
      dialog: false,
      kktToDelete: null,
      modalpopup: false,
      editKktData: null,
    };
  },
  methods: {
    updateOptions(newOptions) {
      this.$emit("update:options", newOptions);
    },
    openEditDialog(item) {
      console.info(item);
      this.editKktData = {
        id: item.id,
        regId: item.regId,
        storageId: item.storageId,
        sbisCompanyId: item.company.id,
        status: item.status.value,
      };
      this.modalpopup = true;
    },
    saveKkt(updatedKkt) {
      this.$emit("edit-item", updatedKkt);
      this.modalpopup = false;
    },
    confirmDelete(item) {
      this.kktToDelete = item;
      this.dialog = true;
    },
  },
};
</script>

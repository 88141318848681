<template>
  <v-card class="pt-5 px-5 mb-12">
    <span class="text-h3 mt-10">Товары</span>
    <v-divider></v-divider>
    <v-row class="text-h5 mt-5">
      <v-col cols="12" md="6"> Билеты x {{ displayCount }}</v-col>
      <v-col cols="12" md="6" align="end">{{
        displayPrice + " Руб"
      }}
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-10">
      <v-col cols="12" md="4">
        <v-text-field v-if="!isSubagent" class="mb-0 custom-text-field" outlined color="orange" min="2" label="Сбор"
          type="number" v-model="markup" @input="formatInput">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-h5 mt-0">
      <v-col cols="12" md="6">Итог</v-col>
      <v-col cols="12" md="6" align="end">{{
        this.totalPrice + " Руб"
      }}
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      require: true,
    },
    displayCount: {
      type: Number,
      require: true,
    },
    displayPrice: {
      type: Number,
      require: true,
    },
    unavailableServices: Array,
    default: [],
  },
  model: {
    prop: "value",
    event: "change-markup",
  },
  data() {
    return {
      markup: "",
    }
  },
  methods: {
    formatInput() {
      const numberValue = parseFloat(this.markup);
      this.markup = numberValue.toFixed(2).toString();
    },
  } ,

  watch: {
    markup(val) {
      const numberValue = parseFloat(val);
      this.$emit("change-markup", numberValue.toFixed(2));
    },
  },
  computed: {
    totalPrice() {
      return Number(this.displayPrice) + Number(this.markup);
    },

    isSubagent() {
      return this.$store.getters.user.role == this.$enums.Roles.SubAgent;
    },
  },
  mounted() {
    this.markup = this.value;
  },
};
</script>
<style>
.custom-text-field input[type='number'] {
  -moz-appearance: textfield;
}

.custom-text-field input::-webkit-outer-spin-button,
.custom-text-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    max-width="290px"
    min-width="auto"
    :nudge-bottom="nudgeBottom"
    allow-overflow
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-mask="mask"
        v-model="inputDate"
        :label="text"
        :hint="displayFormat"
        :rules="disabled ? [] : rules"
        :disabled="disabled"
        :outlined="outlined"
        persistent-hint
        :prepend-inner-icon="prepend_inner_icon"
        v-bind="attrs"
        v-on="on"
        :solo="solo"
        :light="light"
        :color="color"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="pickerDate"
      :min="minDate"
      :max="maxDate"
      color="orange"
      :locale="locale"
      :first-day-of-week="1"
      @input="menu = false"
      :no-title="noTitle"
    >
      <slot></slot>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { VueMaskDirective } from "v-mask";
import { format, parse, isValid } from "date-fns";
import moment from "moment";
export default {
  name: "DateOnlyPicker",
  directives: { mask: VueMaskDirective },
  props: {
    value: {
      type: String,
      require: true,
    },
    format: {
      type: String,
      default: "dd.MM.yyyy",
    },
    rules: {
      type: Array,
      default: function () {
        return [
          (value) => !!value || "Обязательно к заполнению",
          (value) =>
            (value?.length !== null && value.length == 10) ||
            "В формате дд.мм.гггг",
        ];
      },
    },
    min: {
      type: Date,
      default: () => new Date(1900, 1, 1),
    },
    max: {
      type: Date,
      default: () => new Date(2050, 1, 1),
    },
    locale: {
      type: String,
      default: "ru",
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    prepend_inner_icon: {
      type: String,
      default: "mdi-calendar",
    },
    nudgeBottom: {
      type: Number,
      default: 50,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "Дата",
    },
  },
  model: {
    prop: "value",
    event: "change-date",
  },
  data() {
    return {
      current: "",
      pickerDate: null,
      inputDate: "",
      menu: false,
      mask: this.format
        .replace(/[a-z]/gi, "X")
        .replace(/[^\w]/, this.format.match(/[^\w]/)),
    };
  },
  mounted() {
    this.current = this.value;
  },
  watch: {
    current(val, oldVal) {
      console.warn(moment(val, "dd.MM.yyyy"));
      if (!moment(val, "dd.MM.yyyy").isValid()) return;
      this.$emit("change-date", val);
      this.inputDate = val;
      this.pickerDate = this.formatToPicker(val);
    },
    inputDate(val) {
      if (val.length == this.format.length) this.current = val;
    },
    pickerDate(val) {
      if (!val) return;
      this.current = this.formatFromPicker(val);
    },
    value(val, oldVal) {
      if (val == this.current) return;
      if (!val && !!oldVal) {
        this.inputDate = "";
        this.pickerDate = "";
      }
      this.current = val;
    },
  },
  methods: {
    formatToPicker(str) {
      const values = str.split(".");
      console.warn(values);
      return `${values[2]}-${values[1]}-${values[0]}`;
    },
    formatFromPicker(str) {
      const values = str.split("-");
      console.warn(values);
      return `${values[2]}.${values[1]}.${values[0]}`;
    },
  },
  computed: {
    displayFormat() {
      return this.format
        .toLowerCase()
        .replace("yyyy", "ГГГГ")
        .replace("mm", "ММ")
        .replace("dd", "ДД");
    },
    minDate() {
      return format(this.min, "yyyy-MM-dd");
    },
    maxDate() {
      return format(this.max, "yyyy-MM-dd");
    },
  },
};
</script>

<style></style>

<template>
  <v-alert
      v-if="show"
      :value="value"
      @input="update"
      :color="color"
      dense
      outlined
      class="centered-alert"
  >
    <span>{{ message }}</span>
    <v-btn @click="closeAlert" class="close-btn">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-alert>
</template>

<script>
export default {
  name: 'WarningAlert',
  data() {
    return {
      show: false,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'orange',
    },
  },
  model: {
    prop: 'value',
    event: 'changeState'
  },
  watch: {
    value(newValue) {
      this.show = newValue;
    },
  },
  methods: {
    closeAlert() {
      this.show = false;
      this.$emit('changeState', this.show);
    },
    update(event) {
      this.$emit('changeState', event);
    }
  },
};
</script>

<style scoped>
.centered-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
}

.close-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-left: 10px;
}
</style>

export const genders = [
  { text: "Мужской", value: "M" },
  { text: "Женский", value: "F" },
];

export const categories = [
  { text: "Взрослый", value: "1", code: "1" },
  { text: "Ребенок (5-10 лет)", value: "2", code: "2" },
  { text: "Младенец без места (0-5 лет)", value: "3", code: "3" },
];

export const passengerTemplate = {
  documentType: "",
  documentNumber: "",
  lastName: "",
  firstName: "",
  middleName: "",
  birthday: "",
  citizenship: "",
  gender: "",
  type: "",
  email: "",
  sendEmail: 1,
  phone: "",
  tariffs: [],
  bonusCard: [
    {
      segmentType: "string",
      type: "string",
      number: 0,
    },
  ],
};
export const citizenship = [
  {
    text: "Россия",
    value: "RU",
    haveValidTill: 0,
  },
  {
    text: "Другое",
    value: "Впишите гражданство",
    haveValidTill: 0,
  },
];

export const passengerTypes = {
  NoValue: { description: "Не определен", code: "" },
  Full: { description: "Полный", code: "" },
  Child: { description: "Детский", code: "" },
  FreeChild: { description: "Детский без места", code: "" },
  AdditionalFreeChild: {
    description: "Детский безденежный ДОПП (Детский без места по тарифу ДОПП)",
    code: "",
  },
  Senior: { description: "Скидка по тарифу Senior", code: "" },
  Junior: { description: "Скидка по тарифу Junior", code: "" },
  RoundTrip: { description: "Скидка при оформлении Туда/обратно", code: "" },
  RoundTripInInterstateDirection: {
    description:
      "Скидка при оформлении Туда/обратно в межгосударственном сообщении",
    code: "",
  },
  Pupil: { description: "Скидка по школьному тарифу", code: "" },
  UniversalCard: { description: "Скидка по дорожной карте", code: "" },
  Holiday: {
    description: "Скидка по праздничному тарифу (напр., день рождения)",
    code: "",
  },
  Wedding: { description: "Скидка по свадебному тарифу", code: "" },
  Family: { description: "Скидка по семейному тарифу", code: "" },
  Kupek: {
    description:
      "Скидка по тарифу Купек (выкуп одновременно 4-х мест в вагоне купе)",
    code: "",
  },
  Single: {
    description:
      "Скидка по тарифу Сингл (выкуп одновременно 2-х мест в вагоне СВ на одного человека)",
    code: "",
  },
  Birthday: {
    description: "Скидка по тарифу ДЕНЬР (день рождения +-7дней)",
    code: "",
  },
  BirthdayAccompanying: {
    description:
      "Скидка по тарифу ДЕНЬС (для сопровождающего именинника +-7дней)",
    code: "",
  },
  BusinessTravelCard: {
    description: "Оформление по деловому проездному",
    code: "",
  },
  RoundTripForUpperPlaces: {
    description: "Скидка при оформлении Туда/обратно для верхних мест",
    code: "",
  },
  InternalFamily: {
    description:
      "Семейный. Для членов многодетных семей, только во внутреннем сообщении",
    code: "",
  },
  DeputyIdentification: {
    description: "Депутат Государственной Думы (ДГД)",
    code: "",
  },
};

export const docTypes = [
  {
    text: "Российский паспорт",
    value: "C",
    haveValidTill: 0,
  },
  {
    text: "Российский загранпаспорт",
    value: "P",
    haveValidTill: 1,
  },
  {
    text: "Национальный паспорт",
    value: "A",
    haveValidTill: 0,
  },
  {
    text: "Свидетельство о рождении",
    value: "B",
    haveValidTill: 0,
  },
  {
    text: "Воинское удостоверение",
    value: "M",
    haveValidTill: 0,
  },
  {
    text: "Паспорт моряка",
    value: "S",
    haveValidTill: 0,
  },
  {
    text: "Временное удостоверение личности",
    value: "SU",
    haveValidTill: 0,
  },
  {
    text: "Свидетельство на возвращение",
    value: "SV",
    haveValidTill: 0,
  },
  {
    text: "Вид на жительство",
    value: "VJ",
    haveValidTill: 1,
  },
  {
    text: "Удостоверение личности без гражданства",
    value: "BG",
    haveValidTill: 0,
  },
  {
    text: "Дипломатический паспорт",
    value: "DP",
    haveValidTill: 1,
  },
  {
    text: "Служебный паспорт",
    value: "SP",
    haveValidTill: 1,
  },
  {
    text: "Любой паспорт",
    value: "PA",
    haveValidTill: 0,
  },
  {
    text: "Свидетельство о рождении или воинское удостоверение",
    value: "F",
    haveValidTill: 0,
  },
  {
    text: "Удостоверение личности офицера",
    value: "O",
    haveValidTill: 0,
  },
  {
    text: "Справка об утере паспорта",
    value: "L",
    haveValidTill: 0,
  },
  {
    text: "Свидетельство на возвращение в РФ",
    value: "R",
    haveValidTill: 0,
  },
];
export const passengerInfo = {
  documentType: "",
  documentNumber: "",
  lastName: "",
  firstName: "",
  middleName: "",
  birthday: "",
  citizenship: "",
  gender: "",
  category: "",
  email: "",
  phone: "",
  citizenshipSelect: [
    {
      text: "Россия",
      value: "RU",
      haveValidTill: 0,
    },
    {
      text: "Другое",
      value: "Впишите гражданство",
      haveValidTill: 0,
    },
  ],
};
export const railwayOrderStatuses = {
  Booked: "Забронирован",
  Cancelled: "Отменен",
  WaitAuthorization: "Ожидание авторизации",
  RefundAuthorized: "Возврат денег разрешен",
  Refunded: "Возвращено",
  PartiallyRefunded: "Частично возвращено",
  Paid: "Оплачено",
  Ticketed: "Выписано",
  AwaitPayment: "Выписка и оплата возможны через саппорт",
  Annulment: "Аннулировано",
  TicketedInProvider: "Выписан у поставщика, но не оплачен у нас",
  PartiallyAnnulment: "Частично аннулировано",
  PartiallyTicketed: "Частично выписано",
  Exchanged: "Произведен обмен",
  PartiallyExchanged: "Частичный обмен",
};

export const statuses = [
  { text: "All", value: "" },
  { text: "Booked", value: 2 },
  { text: "Bought", value: 3 },
  { text: "Book Canceled", value: 4 },
  { text: "Void", value: 5 },
  { text: "Returned", value: 6 },
];

export const railwayStatuses = [
  { text: "Все", value: "" },
  { text: "Забронировано", value: "Booked" },
  { text: "Оплачено", value: "Paid" },
  { text: "Отменено", value: "Cancelled" },
  { text: "Аннулировано", value: "Annulment" },
  { text: "Возвращено", value: "Refunded" },
  { text: "Частично возвращено", value: "PartiallyRefunded" },
  { text: "Выписано", value: "Ticketed" },
  { text: "Частично выписано", value: "PartiallyTicketed" },
  {
    text: "Выписано у поставщика, не оплачено у нас",
    value: "TicketedInProvider",
  },
  { text: "Ожидание авторизации", value: "WaitAuthorization" },
  { text: "Возврат денег разрешен", value: "RefundAuthorized" },
  { text: "Выписка и оплата возможны через саппорт", value: "AwaitPayment" },
  { text: "Частично аннулировано", value: "PartiallyAnnulment" },
  { text: "Произведен обмен", value: "Exchanged" },
  { tex: "Частичный обмен", value: "PartiallyExchanged" },
];
export const providers = [
  { text: "All", value: "" },
  { text: "MixVel", value: "MixVel" },
  { text: "Nemo", value: "Nemo" },
];

export const kktStatuses = [
  { text: "Не зарегистрирован", value: 0 },
  { text: "Активен", value: 2 },
  { text: "Снят с регистрации", value: 3 },
  { text: "Ожидает активации", value: 4 },
  { text: "Нет лицензии", value: 6 },
];

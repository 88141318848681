<template>
  <v-card flat class="px-5 pb-5">
    <v-card-title class="pb-8">Получение квитанции об оплате</v-card-title>
    <v-row class="px-4" justify="center">
      <v-col>
        <v-text-field
          outlined
          label="Номер билета"
          v-model="number"
          style="height: 3.5rem"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          label="Стоимость перевозки"
          v-model="sum"
          style="height: 3.5rem"
          :rules="[(val) => val > 0 || 'Введите число, например 10.50']"
        >
        </v-text-field>
      </v-col>
      <v-col class="col-3">
        <v-text-field
          outlined
          label="Сбор"
          v-model="markup"
          style="height: 3.5rem"
          :rules="[(val) => val >= 0 || 'Введите число, например 10.50']"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="space-between" class="px-4">
      <v-col cols="12" md="8" class="pt-6"
        ><v-icon class="mr-3">mdi-information</v-icon> Не забудьте распечатать
        оригинальную маршрут-квитанцию</v-col
      >
      <v-col cols="12" md="4" class="text-center">
        <v-btn
          @click="submit"
          :loading="isDownload"
          color="orange"
          :disabled="isDisable"
          style="width: 100%; height: 3.3rem"
        >
          Получить
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { downloadFile } from "@/mixins/utils.js";
export default {
  name: "PaymentReceipt",
  data() {
    return {
      number: "",
      markup: "",
      sum: "",
      isDownload: false,
    };
  },
  methods: {
    async submit() {
      this.isDownload = true;
      try {
        let resp = await this.$axios.get(
          this.$enums.Endpoints.Other.Receipt.PaymentInfo +
            `?number=${this.number}&sum=${this.sum}&markup=${this.markup}`
        );
        let uint8Array = new Uint8Array(
          atob(resp.data)
            .split("")
            .map(function (c) {
              return c.charCodeAt(0);
            })
        );
        downloadFile(uint8Array, { name: `${this.number}_receipt.pdf` });
      } catch (e) {
        throw e;
      } finally {
        this.isDownload = false;
      }
    },
  },
  computed: {
    isDisable() {
      return this.number.length < 4 || !this.markup.length || !this.sum.length;
    },
  },
};
</script>

<style lang="scss" scoped></style>

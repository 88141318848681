<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>{{ dialogTitle }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Регистрационный номер"
                v-model="localData.regId"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Номер ФН"
                v-model="localData.storageId"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="localData.sbisCompanyId"
                :items="companies"
                label="Выберите компанию"
              ></v-select>
            </v-col>
            <v-col v-if="!isNew" cols="12">
              <v-select
                label="Статус"
                :items="statuses"
                v-model="localData.status"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" text @click="closeDialog">Отмена</v-btn>
        <v-btn color="orange" text @click="saveData">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { kktStatuses } from "@/services/constants";

export default {
  name: "EditDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    dialogTitle: {
      type: String,
      required: true,
    },
    kktData: {
      type: Object,
      required: true,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: this.value,
      localData: {},
      statuses: kktStatuses,
      companies: [],
    };
  },
  watch: {
    value(newVal) {
      this.dialog = newVal;
    },
    dialog(newVal) {
      this.$emit("input", newVal);
    },
    kktData(newVal) {
      this.localData = { ...newVal };
    },
  },
  methods: {
    async fetchCompanies() {
      try {
        const response = await axios.get(this.$enums.Endpoints.Data.Logins);
        this.companies = response.data.map((e) => {
          return { text: e.login, value: e.id };
        });
      } catch (error) {
        console.error("Ошибка при загрузке компаний:", error);
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    saveData() {
      this.$emit("save", this.localData);
    },
  },
  mounted() {
    this.fetchCompanies();
    this.localData = {
      id: this.kktData.id,
      regId: this.kktData.regId,
      storageId: this.kktData.storageId,
      sbisCompanyId: this.kktData.company.id,
      status: this.kktData.statusId,
    };
  },
};
</script>

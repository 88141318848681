<template>
  <v-card flat class="px-5 pb-5">
    <v-card-title class="pb-8">Получение маршрутки клиента</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4" class="text-color">
          <v-text-field outlined label="Id (Guid)" v-model="value" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row class="px-4">
        <v-col cols="6" md="3">
          <v-btn
            block
            dark
            color="orange"
            :disabled="isDownload"
            style="width: 100%; height: 3.3rem"
            @click="clear"
            >Очистить форму
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="6" md="3">
          <v-btn
            block
            dark
            :loading="isDownload"
            color="green"
            :disabled="isDisabled"
            style="width: 100%; height: 3.3rem"
            @click="download"
            >Получить
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { downloadFile } from "@/mixins/utils.js";

export default {
  name: "Wwss",
  data() {
    return {
      value: "",
      isDownload: false,
    };
  },
  methods: {
    async download() {
      this.isDownload = true;
      try {
        let resp = await this.$axios.get(
          this.$enums.Endpoints.Admin.Receipt.ShowClient + `?id=${this.value}`
        );
        let uint8Array = new Uint8Array(
          atob(resp.data)
            .split("")
            .map(function (c) {
              return c.charCodeAt(0);
            })
        );
        downloadFile(uint8Array, { name: `${this.value.customer}_wwss.pdf` });
      } catch (e) {
        console.error(e);
      } finally {
        this.isDownload = false;
      }
    },
    clear() {
      this.value = "";
    },
  },
  computed: {
    isDisabled() {
      return !this.value;
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <v-autocomplete
      v-model="selectedAccountId"
      :items="filteredAccounts"
      :search-input.sync="searchInputAccount"
      :rules="accountRules"
      :label="label"
      required
      ref="input_account"
      @input="onAccountSelect"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "InputAccount",

  props: {
    value: { type: Number, required: true },
    label: { type: String, required: false, default: "" },
    endpoint: { type: String, default: "/Account/GetList" },
  },

  model: { prop: "value", event: "update:value" },
  emits: ["update:value"],

  data() {
    return {
      selectedAccountId: this.value,
      searchInputAccount: null,
      allAccounts: [],
      filteredAccounts: [],
      accountRules: [(v) => !!v || "Выберите аккаунт"],
    };
  },

  async mounted() {
    this.allAccounts = await this.loadAllAccounts();
    this.filteredAccounts = this.allAccounts;
  },

  watch: {
    value(newVal) {
      this.selectedAccountId = newVal;
    },
  },

  methods: {
    async loadAllAccounts() {
      const accounts = [];
      const url = this.endpoint;
      try {
        const resp = await this.$axios.get(url);
        resp.data.results.forEach((account) => {
          accounts.push({
            text: `${account.name} (${account.email})`,
            value: account.id,
          });
        });
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
      }
      accounts.sort((a, b) => a.text.localeCompare(b.text));
      return accounts;
    },

    onAccountSelect(accountId) {
      this.$emit("update:value", accountId);
      console.log("Выбранный аккаунт ID:", accountId);
    },
  },
};
</script>

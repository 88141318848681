<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="3">
        <v-tabs vertical v-model="tab">
          <v-tab>Отправка СМС</v-tab>
          <v-tab>Полная МК</v-tab>
          <v-tab>Получение чека</v-tab>
          <v-tab>Повторные отправки SMS</v-tab>
          <v-tab>CRUD для ККТ</v-tab>
          <v-tab>Получение PDF клиента</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12" md="9">
        <v-tabs-items v-model="tab">
          <v-tab-item :value="0">
            <get-and-send-receipt />
          </v-tab-item>
          <v-tab-item :value="1">
            <geting-route />
          </v-tab-item>
          <v-tab-item :value="2">
            <geting-check />
          </v-tab-item>
          <v-tab-item :value="3">
            <sms-monitoring />
          </v-tab-item>
          <v-tab-item :value="4">
            <KKTCrud />
          </v-tab-item>
          <v-tab-item :value="5">
            <client-pdf />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GetingRoute from "@/components/AdminkaPage/GetingRoute.vue";
import GetingCheck from "@/components/AdminkaPage/GetingCheck.vue";
import GetAndSendReceipt from "@/components/AdminkaPage/GetAndSendReceipt.vue";
import SmsMonitoring from "@/components/AdminkaPage/SmsMonitoring.vue";
import KKTCrud from "@/components/AdminkaPage/KktCrud.vue";
import ClientPdf from "@/components/AdminkaPage/ClientPdf.vue";

export default {
  components: {
    GetAndSendReceipt,
    GetingRoute,
    GetingCheck,
    SmsMonitoring,
    KKTCrud,
    ClientPdf,
  },
  data() {
    return {
      tab: 0,
      disabledAccounts: [15, 25, 37, 38, 39, 40, 41, 42],
    };
  },
};
</script>

<style></style>
